
import { defineComponent, ref, onMounted } from "vue";
import { notification } from "ant-design-vue";
import superadminService from "../services/superadmin";
import authServices from "../services/auth";
import {
} from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    InfoCircleOutlined
  },
  setup() {
    const pageLoader = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const route = useRoute();
    const authSuccess = ref<any>();
    const authCode = ref<any>();
    const clientName = ref();
    const user = ref<any>();
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const setAuthRequest = async () => {
      // console.log('subdomain', route.query.subdomain);
      pageLoader.value = true;
      let data = '?client_id='+ route.query.client_id +'&redirect_uri=' +route.query.redirect_uri+ '&response_type='+ route.query.response_type +'&scope='+ route.query.scope+'&email='+ route.query.email+'&subdomain='+ route.query.subdomain;
      const profile = JSON.parse(localStorage.getItem('profile'));
      user.value= JSON.parse(localStorage.getItem('profile'));
      const redirect_uri = route.query.redirect_uri;
      var hyper;
      if(redirect_uri.indexOf('localhost') > -1) {
        hyper = 'http';
      } else {
        hyper = 'https';
      }
      if(profile && profile.email != route.query.email) {
        logout();        
        window.location.href = hyper + '://'+ redirect_uri +'?email_error=true';
      }
      try {
        const res = await superadminService.setAuthorization(data);
        if (res.data.data.success) {
          clientName.value = res.data.data.data.client.name;
          if(res.data.data.data.subdomainError){
            logout();
            window.location.href = hyper + '://'+ redirect_uri + '?subdomain_error=true' ;
          } else {
            if(res.data.data.data.authToken.id) {
              window.location.href = hyper + '://'+ redirect_uri +'?code='+ res.data.data.data.authToken.id;
              // setTimeout(() => {
              //   pageLoader.value = false;
              // }, 3000);
            } else {
              pageLoader.value = false;
            }
          }          
          authSuccess.value = res.data.data;
        }
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          // await notify("Error", error.response.data.message, "error");
        }
        pageLoader.value = false;
      }
    };
    const authorizeApp = async () => {
      confirmLoading.value = true;     
      const data = {
        'redirect_uri': route.query.redirect_uri,
        'state': '',
        // 'client_id': authSuccess.value.data.data.client.id,
        // 'auth_token': authSuccess.value.data.authToken.id,
        // 'user_id': authSuccess.value.data.user,
        'logezy_email': route.query.email,
        'client_id': route.query.client_id
      };
      try {
        const res = await superadminService.authorize(data);
        if (res.data.data.success) {
          const url = route.query.redirect_uri;
          var hyper
          if(url.indexOf('localhost') > -1) {
            hyper = 'http';
          } else {
            hyper = 'https';
          }

          authCode.value = res.data.data.data;
          window.location.href = hyper + '://'+ url +'?code='+authCode.value.auth_code;
        }
        confirmLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          await notify("Error", error.response.data.message, "error");
        }
        confirmLoading.value = false;
      }
    };
    const cancelRequest = async () => {
      confirmLoading.value = true;
      const redirect_uri = route.query.redirect_uri;
      var hyper;
      if(redirect_uri.indexOf('localhost') > -1) {
        hyper = 'http';
      } else {
        hyper = 'https';
      }
      logout();        
      window.location.href = hyper + '://'+ redirect_uri +'?authorize_error=true';      
    };
    const logout = async () => {
       try {
        const res = await authServices.logout();
        if (res.data.message === 'Successfully logged out') {
          delete localStorage.accessToken
          delete localStorage.profile
          localStorage.clear()
        }
      } catch (error: any) {
        console.log(error);
      }
    };
    onMounted(async () => {
      setAuthRequest();
    });
    return {
        authorizeApp,
        cancelRequest,
        confirmLoading,
        notify,
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
        authSuccess,
        logout,
        pageLoader,
        clientName,
        user
    };
  },
});
